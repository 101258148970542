const create = (api) => {

    const getRMAOverview = (startDate, endDate, siteId, includeChildren=true, sort='model', directionSort=1, searchTerm, language, pageNumber=1, pageSize=100) => {
        const query = searchTerm === undefined ? `&query=` : `&query=${searchTerm}`
        return api.get(`/rma/${siteId}?startDate=${startDate}&endDate=${endDate}&includeChildren=${includeChildren}&sort=${sort}&language=${language}&directionSort=${directionSort}`+ `${query}` +`&pageNumber=${pageNumber}&pageSize=${pageSize}`)
    }

    const getRMADetailData = (siteId, rmaId) => {
        return api.get(`/rma/${siteId}/detail?rmaId=${rmaId}`)
    }

    const getExportDeviceCVSFile = (siteId, payload, params, startDate, endDate) =>
        api.post(`/v2/devices/exportfiles?siteId=${siteId}&startDate=${startDate}&endDate=${endDate}`, payload, { params });

    
    return {
        getRMAOverview,
        getExportDeviceCVSFile,
        getRMADetailData
    }
}

export default {
  create
}