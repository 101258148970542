const create = (api) => {
  const getBatteryDischargeRateCard = (startDate, endDate, siteId, sort='serialNumber', directionSort='1', searchTerm, language, pageNumber = 1, pageSize=100) => {
    const query = searchTerm === undefined ? `&query=` : `&query=${searchTerm}`
    return api.get(`/dashboard/deviceutilization/batterydischargerate/${siteId}/details?startDate=${startDate}&endDate=${endDate}&includeChildren=true&pageNumber=${pageNumber}&pageSize=${pageSize}`+ `${query}` + `&sort=${sort}&directionSort=${directionSort}&language=${language}`)
  }
  
  const batteryDischargerDevice = (siteId, startDate, endDate, serialNumber) => {
    return api.get(`/dashboard/deviceutilization/batterydischargerate/${serialNumber}?siteId=${siteId}&startDate=${startDate}&endDate=${endDate}`)
  }

  const batteryDischargerOrganization = (startDate, endDate, siteId) => {
    if(siteId) {
      return api.get(`/dashboard/deviceutilization/batterydischargerate?siteId=${siteId}&startDate=${startDate}&endDate=${endDate}&includeChildren=true`)
    } else {
      return api.get(`/dashboard/deviceutilization/batterydischargerate?startDate=${startDate}&endDate=${endDate}`)
    }
    
  }


  return {
    getBatteryDischargeRateCard,
    batteryDischargerDevice,
    batteryDischargerOrganization
  }

}

export default {
  create
}
