import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-translate'

import { StatusWrapper, StyledBadge } from './device-status.styles'

export function useAssetStatusToColor ({
  status
}) {
  const t = useTranslate('ConnectionStatus')
  return useMemo(() => {
    switch (status) {
      case 'connected':
        return {
          color: 'green',
          statusText: t('Connected')
        }
      case 'disconnected':
        return {
          color: 'red',
          statusText: t('Disconnected')
        }
      case 'not connected':
        return {
          color: 'orange',
          statusText: t('Provisioned')
        }
      case 'deprovisioning':
        return {
          color: 'orange',
          statusText: t('Deprovisioning')
        }
      case 'deprovisioned':
        return {
          color: 'orange',
          statusText: t('Deprovisioned')
        }
      case 'deregistering':
        return {
          color: 'orange',
          statusText: t('Deregistering')
        }
      case 'deregistered':
        return {
          color: 'orange',
          statusText: t('Deregistered')
        }
      case 'unknown':
        return {
          color: 'grey',
          statusText: t('Unknown')
        }
      default:
        return {
          statusText: t('Not available')
        }
    }
  }, [status, t])
}

const DeviceStatus = ({ badgeOnly, ...props }) => {
  const { color, statusText } = useAssetStatusToColor(props)

  return (
    <StatusWrapper
      data-testid="statusValue"
    >
      {color && <StyledBadge color={color} empty />}
      {statusText}
    </StatusWrapper>
  )
}

DeviceStatus.propTypes = {
  status: PropTypes.string.isRequired
}

DeviceStatus.defaultProps = {
  t: label => label
}
export default DeviceStatus
