import config from 'Config/AppConfig'

export const redirectToWorkforceIntelligence = () => {
    const url = config.api.WorkforceIntelligence
    const _linkElement = document.createElement('a')
    _linkElement.href = url
    _linkElement.target = '_blank'
    _linkElement.rel = 'noopener noreferrer'
    _linkElement.click()
}

export const redirectToLogAnalyzer = (accessToken) => {
    const url = `${config.api.logAnalyzerURL}?accessToken=${accessToken}`
    const _linkElement = document.createElement('a')
    _linkElement.href = url
    _linkElement.target = '_blank'
    _linkElement.rel = 'noopener noreferrer'
    _linkElement.click()
}