import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { SuccessToast, ErrorToast } from '../../Themes/ScufStyledComponents'

import {
  TagsContainer
} from './tags.styles'
import Tag from './components/tag'
import Controls from './components/controls'
import AddTags from './components/add-tags'
import EditTags from './components/edit-tags'
import RemoveTags from './components/remove-tags'
import { TAG_MANAGEMENT_TOAST_CONTAINER } from '../../Store/tagging/constants'
import { StyledToastContainer } from '../toasts'
import { translate } from 'react-translate'
import { SimpleRow } from 'Components/AssetManagementBase/asset-management-base.styles'
import { defaultFlags, flagsPropTypes } from 'Utils/launch-darkly-flags'

const Tags = ({
  tags,
  createTag,
  removeTag,
  selectedTags,
  loading,
  devices,
  setIsEditing,
  isAssetDetail,
  siteId,
  permissions,
  flags,
  t,
  deviceDbStatus,
  ...rest
}) => {
  const [isAddingTag, setIsAddingTag] = useState(false)
  const [isEdittingTag, setIsEditingTag] = useState(false)
  const [isRemovingTag, setIsRemovingTag] = useState(false)
  const isLicensingEnabled = (flags.licensing && deviceDbStatus === 'unlicensed')
  const showRequestResult = (result, numDevices) => {
    if (result) {
      toast(<SuccessToast data-testid='Toast' message={`Successfully removed tags from ${numDevices} devices!`} />, { testid:'successToast', containerId: TAG_MANAGEMENT_TOAST_CONTAINER })
    } else {
      toast(<ErrorToast data-testid='errorToast' message={`Error while removing tags for ${numDevices} devices..`} />, { testid:'errorToast', containerId: TAG_MANAGEMENT_TOAST_CONTAINER })
    }
  }

  useEffect(() => {
    if (loading) {
      setIsEditingTag(false)
    }
  }, [loading])

  if (!isEdittingTag) {
    return <Fragment>
      {isAddingTag &&
        <AddTags
          createTag={createTag}
          result={showRequestResult}
          setIsAddingTag={setIsAddingTag}
        />
      }
      {!isAddingTag &&
        <Controls
          editRemove={selectedTags && selectedTags.length}
          onAdd={() => setIsAddingTag(true)}
          onEdit={() => {
            setIsEditingTag(true)
            setIsEditing(true)
          }}
          onRemove={() => {
            setIsRemovingTag(true)
          }}
          permissions={permissions}
          deviceDbStatus={deviceDbStatus}
        />
      }
      { 
        <SimpleRow className={isLicensingEnabled ? 'disableOptions' : 'pointer'}>
          { t('AllFieldsMustBeFilled')}
        </SimpleRow>
      }
      <TagsContainer>
        {tags.map((tag, i) =>
          <Tag
            data-testid={tag}
            id={tag}
            key={i}
          />
        )}
      </TagsContainer>
      <RemoveTags
        devices={devices}
        isOpen={isRemovingTag}
        onCloseRemove={() => {
          setIsRemovingTag(false)
        }}
        result={showRequestResult}
        selectedTags={selectedTags}
      />
      <StyledToastContainer
        closeButton={false}
        closeOnClick={true}
        containerId={TAG_MANAGEMENT_TOAST_CONTAINER}
        enableMultiContainer={true}
        hideProgressBar={true}
        newestOnTop={true}
        toastClassName='toast-notification-wrap'
      />
    </Fragment>
  } else {
    return <EditTags
      isAssetDetail={isAssetDetail}
      onCloseEdit={() => {
        setIsEditingTag(false)
        setIsEditing(false)
      }}
      result={showRequestResult}
      selectedTags={selectedTags}
      siteId={siteId}
    />
  }
}

Tags.defaultProps = {
  isAssetDetail: false,
  flags: defaultFlags,
  t: label => label,
  t: PropTypes.func
}

Tags.propTypes = {
  t: PropTypes.func,
  flags:flagsPropTypes,
  tags: PropTypes.array,
  t: () => null
}

export default translate('TaggingView')(Tags)
