import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from '@scuf/common'
import { Title } from '../../../../Themes/ScufStyledComponents'
import { TagsApi } from 'Services'
import { CustomStyledModal } from './remove-tags.styles'

const RemoveTags = ({
  devices,
  getTags,
  assetsWithTags,
  onCloseRemove,
  isOpen,
  result,
  t,
  ...rest
}) => {
  const removeTags = async (assets) => {
    const response = await TagsApi.removeTagsForGivenAssets(assets)
    if (response.ok) {
      getTags(devices)
      result(true, devices.length)
    }
  }
  return (
    <CustomStyledModal closeIcon={true} onClose={onCloseRemove} open={isOpen} size='mini'>
      <CustomStyledModal.Header>
        <Title data-testid='removetagTitle'>{t('RemoveTagsTitle')}</Title>
      </CustomStyledModal.Header>
      <CustomStyledModal.Content data-testid='removetagMessage'>
	      {t('RemoveTagsMessage', {value: devices.length})}
      </CustomStyledModal.Content>
      <CustomStyledModal.Footer>
        <Button data-testid='submitButton' content={t('Submit')} onClick={() => {
          removeTags(assetsWithTags)
          onCloseRemove()
        }} type='primary' />
      </CustomStyledModal.Footer>
    </CustomStyledModal>
  )
}

RemoveTags.defaultProps = {
  devices: [],
  isOpen: true,
  selectedTags: [],
  t: label => label
}
RemoveTags.propTypes = {
  t: PropTypes.func,
  devices: PropTypes.array,
  onCloseRemove: PropTypes.func,
  isOpen: PropTypes.bool
}

export default RemoveTags
