export const fedexxData = [
  {
    tag: 'TAG1000101',
    type: 'Mobile Computer',
    status: 'Available',
    user: 'Bruce Wayne',
    checkoutDate: '09/26/2019 12:00:00',
    checkinDate: '09/28/2019 12:00:00',
    dueDate: 'N/A',
    notes: ''
  },

  {
    tag: 'TAG1000106',
    type: 'Mobile Computer',
    status: 'Damaged',
    user: 'Bruce Wayne',
    checkoutDate: '08/28/2019 20:00:00',
    checkinDate: 'N/A',
    dueDate: '08/30/2019 20:00:00',
    notes: 'Broken'
  }
]

export const fedexData = [

  {
    "tag": "CN8018137D8217",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Lisa",
    "checkoutDate": "10/02/2019",
    "dueDate": "10/02/2019",
    "checkinDate": "",
    "notes": ""
  },
  {
    "tag": "CN8018136D8253",
    "type": "Mobile Computer",
    "status": "CHECK IN",
    "user": "Maddison",
    "checkoutDate": "10/02/2019",
    "dueDate": "10/02/2019",
    "checkinDate": "10/02/2019",
    "notes": ""
  },
  {
    "tag": "CN8018136D8236",
    "type": "Mobile Computer",
    "status": "CHECK IN",
    "user": "Emmy",
    "checkoutDate": "10/02/2019",
    "dueDate": "10/02/2019",
    "checkinDate": "10/02/2019",
    "notes": ""
  },
  {
    "tag": "CN8018136D8305",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Ashley",
    "checkoutDate": "10/02/2019",
    "dueDate": "10/02/2019",
    "checkinDate": "",
    "notes": ""
  },
  {
    "tag": "CN8018136D81A1",
    "type": "Mobile Computer",
    "status": "CHECK IN",
    "user": "Sam",
    "checkoutDate": "10/02/2019",
    "dueDate": "10/02/2019",
    "checkinDate": "10/02/2019",
    "notes": ""
  },
  {
    "tag": "CN8018136D8179",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Harry",
    "checkoutDate": "10/02/2019",
    "dueDate": "10/02/2019",
    "checkinDate": "",
    "notes": ""
  },
  {
    "tag": "CN8018142D8300",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Carl",
    "checkoutDate": "10/02/2019",
    "dueDate": "10/02/2019",
    "checkinDate": "",
    "notes": ""
  },
  {
    "tag": "CN8018171D8447",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Mark",
    "checkoutDate": "10/02/2019",
    "dueDate": "",
    "checkinDate": "",
    "notes": ""
  },
  {
    "tag": "CN8018138D8329",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Larry",
    "checkoutDate": "10/02/2019",
    "dueDate": "10/02/2019",
    "checkinDate": "",
    "notes": ""
  },


  {
    "tag": "CN8018137D8217",
    "type": "Mobile Computer",
    "status": "CHECK IN",
    "user": "Lisa",
    "checkoutDate": "10/01/2019",
    "dueDate": "10/01/2019",
    "checkinDate": "10/01/2019",
    "notes": ""
  },
  {
    "tag": "CN8018136D8253",
    "type": "Mobile Computer",
    "status": "CHECK IN",
    "user": "Maddison",
    "checkoutDate": "10/01/2019",
    "dueDate": "10/01/2019",
    "checkinDate": "10/01/2019",
    "notes": ""
  },
  {
    "tag": "CN8018136D8236",
    "type": "Mobile Computer",
    "status": "CHECK IN",
    "user": "Emmy",
    "checkoutDate": "10/01/2019",
    "dueDate": "10/01/2019",
    "checkinDate": "10/01/2019",
    "notes": ""
  },
  {
    "tag": "CN8018136D8305",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Ashley",
    "checkoutDate": "10/01/2019",
    "dueDate": "10/01/2019",
    "checkinDate": "10/01/2019",
    "notes": ""
  },
  {
    "tag": "CN8018136D81A1",
    "type": "Mobile Computer",
    "status": "CHECK IN",
    "user": "Sam",
    "checkoutDate": "10/01/2019",
    "dueDate": "10/01/2019",
    "checkinDate": "10/01/2019",
    "notes": ""
  },
  {
    "tag": "CN8018140D8260",
    "type": "Mobile Computer",
    "status": "LOST",
    "user": "William",
    "checkoutDate": "10/01/2019",
    "dueDate": "10/01/2019",
    "checkinDate": "10/01/2019",
    "notes": "last seen on DC, backdoor"
  },
  {
    "tag": "CN8018136D8179",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Harry",
    "checkoutDate": "10/01/2019",
    "dueDate": "10/01/2019",
    "checkinDate": "10/01/2019",
    "notes": ""
  },
  {
    "tag": "CN8018142D8300",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Carl",
    "checkoutDate": "10/01/2019",
    "dueDate": "10/01/2019",
    "checkinDate": "10/01/2019",
    "notes": ""
  },
  {
    "tag": "CN8018137D822B",
    "type": "Mobile Computer",
    "status": "DAMAGED",
    "user": "Elton",
    "checkoutDate": "10/01/2019",
    "dueDate": "10/01/2019",
    "checkinDate": "10/01/2019",
    "notes": "Dropped device, caused screen break"
  },
  {
    "tag": "CN8018171D8447",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Mark",
    "checkoutDate": "10/01/2019",
    "dueDate": "10/01/2019",
    "checkinDate": "10/01/2019",
    "notes": ""
  },
  {
    "tag": "CN8018138D8329",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Larry",
    "checkoutDate": "10/01/2019",
    "dueDate": "10/01/2019",
    "checkinDate": "10/01/2019",
    "notes": ""
  },
  {
    "tag": "CN8018140D82A6",
    "type": "Mobile Computer",
    "status": "LOST",
    "user": "Max",
    "checkoutDate": "10/01/2019",
    "dueDate": "10/01/2019",
    "checkinDate": "10/01/2019",
    "notes": "Forgotten in Danville's mall"
  },


  {
    "tag": "CN8018136D835F",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "William ",
    "checkoutDate": "09/28/2019",
    "dueDate": "09/28/2019",
    "checkinDate": "",
    "notes": null
  },
  {
    "tag": "CN8018137D8217",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Lisa",
    "checkoutDate": "09/28/2019",
    "dueDate": "09/28/2019",
    "checkinDate": "09/28/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8253",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Maddison",
    "checkoutDate": "09/28/2019",
    "dueDate": "09/28/2019",
    "checkinDate": "09/28/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8236",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Emmy",
    "checkoutDate": "09/28/2019",
    "dueDate": "09/28/2019",
    "checkinDate": "09/28/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8305",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Ashley",
    "checkoutDate": "09/28/2019",
    "dueDate": "09/28/2019",
    "checkinDate": "09/28/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D81A1",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Sam",
    "checkoutDate": "09/28/2019",
    "dueDate": "09/28/2019",
    "checkinDate": "09/28/2019",
    "notes": null
  },
  {
    "tag": "CN8018140D8260",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Wilson",
    "checkoutDate": "09/28/2019",
    "dueDate": "09/28/2019",
    "checkinDate": "09/28/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8179",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Harry",
    "checkoutDate": "09/28/2019",
    "dueDate": "09/28/2019",
    "checkinDate": "09/28/2019",
    "notes": null
  },
  {
    "tag": "CN8018142D8300",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Carl",
    "checkoutDate": "09/28/2019",
    "dueDate": "09/28/2019",
    "checkinDate": "09/28/2019",
    "notes": null
  },
  {
    "tag": "CN8018137D822B",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Elton",
    "checkoutDate": "09/28/2019",
    "dueDate": "09/28/2019",
    "checkinDate": "09/28/2019",
    "notes": null
  },
  {
    "tag": "CN8018171D8447",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Mark",
    "checkoutDate": "09/28/2019",
    "dueDate": "09/28/2019",
    "checkinDate": "09/28/2019",
    "notes": null
  },
  {
    "tag": "CN8018138D8329",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Larry",
    "checkoutDate": "09/28/2019",
    "dueDate": "09/28/2019",
    "checkinDate": "09/28/2019",
    "notes": null
  },
  {
    "tag": "CN8018140D82A6",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Max",
    "checkoutDate": "09/28/2019",
    "dueDate": "09/28/2019",
    "checkinDate": "09/28/2019",
    "notes": null
  },

  {
    "tag": "CN8018136D835F",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "William ",
    "checkoutDate": "09/27/2019",
    "dueDate": "09/27/2019",
    "checkinDate": "09/27/2019",
    "notes": null
  },
  {
    "tag": "CN8018137D8217",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Lisa",
    "checkoutDate": "09/27/2019",
    "dueDate": "09/27/2019",
    "checkinDate": "09/27/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8224",
    "type": "Mobile Computer",
    "status": "AVAILABLE",
    "user": "",
    "checkoutDate": "",
    "dueDate": "",
    "checkinDate": "",
    "notes": null
  },
  {
    "tag": "CN8018136D8253",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Maddison",
    "checkoutDate": "09/27/2019",
    "dueDate": "09/27/2019",
    "checkinDate": "09/27/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8236",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Emmy",
    "checkoutDate": "09/27/2019",
    "dueDate": "09/27/2019",
    "checkinDate": "09/27/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8305",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Ashley",
    "checkoutDate": "09/27/2019",
    "dueDate": "09/27/2019",
    "checkinDate": "09/27/2019",
    "notes": null
  },
  {
    "tag": "CN8018138D82B5",
    "type": "Mobile Computer",
    "status": "AVAILABLE",
    "user": "",
    "checkoutDate": "",
    "dueDate": "",
    "checkinDate": "",
    "notes": null
  },
  {
    "tag": "CN8018136D81A1",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Sam",
    "checkoutDate": "09/27/2019",
    "dueDate": "09/27/2019",
    "checkinDate": "09/27/2019",
    "notes": null
  },
  {
    "tag": "CN8018140D8260",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Wilson",
    "checkoutDate": "09/27/2019",
    "dueDate": "09/27/2019",
    "checkinDate": "09/27/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8179",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Harry",
    "checkoutDate": "09/27/2019",
    "dueDate": "09/27/2019",
    "checkinDate": "09/27/2019",
    "notes": null
  },
  {
    "tag": "CN8018142D8300",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Carl",
    "checkoutDate": "09/27/2019",
    "dueDate": "09/27/2019",
    "checkinDate": "09/27/2019",
    "notes": null
  },
  {
    "tag": "CN8018137D822B",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Elton",
    "checkoutDate": "09/27/2019",
    "dueDate": "09/27/2019",
    "checkinDate": "09/27/2019",
    "notes": null
  },
  {
    "tag": "CN8018171D8447",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Mark",
    "checkoutDate": "09/27/2019",
    "dueDate": "09/27/2019",
    "checkinDate": "09/27/2019",
    "notes": null
  },
  {
    "tag": "CN8018138D8329",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Larry",
    "checkoutDate": "09/27/2019",
    "dueDate": "09/27/2019",
    "checkinDate": "09/27/2019",
    "notes": null
  },
  {
    "tag": "CN8018140D82A6",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Max",
    "checkoutDate": "09/27/2019",
    "dueDate": "09/27/2019",
    "checkinDate": "09/27/2019",
    "notes": null
  },

    {
    "tag": "CN8018136D835F",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "William ",
    "checkoutDate": "09/26/2019",
    "dueDate": "09/26/2019",
    "checkinDate": "09/26/2019",
    "notes": null
  },
  {
    "tag": "CN8018137D8217",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Lisa",
    "checkoutDate": "09/26/2019",
    "dueDate": "09/26/2019",
    "checkinDate": "09/26/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8224",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Taylor",
    "checkoutDate": "09/26/2019",
    "dueDate": "09/26/2019",
    "checkinDate": "09/26/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8253",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Maddison",
    "checkoutDate": "09/26/2019",
    "dueDate": "09/26/2019",
    "checkinDate": "09/26/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8236",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Emmy",
    "checkoutDate": "09/26/2019",
    "dueDate": "09/26/2019",
    "checkinDate": "09/26/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8305",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Ashley",
    "checkoutDate": "09/26/2019",
    "dueDate": "09/26/2019",
    "checkinDate": "09/26/2019",
    "notes": null
  },
  {
    "tag": "CN8018138D82B5",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Donald",
    "checkoutDate": "09/26/2019",
    "dueDate": "09/26/2019",
    "checkinDate": "09/26/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D81A1",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Sam",
    "checkoutDate": "09/26/2019",
    "dueDate": "09/26/2019",
    "checkinDate": "09/26/2019",
    "notes": null
  },
  {
    "tag": "CN8018140D8260",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Wilson",
    "checkoutDate": "09/26/2019",
    "dueDate": "09/26/2019",
    "checkinDate": "09/26/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8179",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Harry",
    "checkoutDate": "09/26/2019",
    "dueDate": "09/26/2019",
    "checkinDate": "09/26/2019",
    "notes": null
  },
  {
    "tag": "CN8018142D8300",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Carl",
    "checkoutDate": "09/26/2019",
    "dueDate": "09/26/2019",
    "checkinDate": "09/26/2019",
    "notes": null
  },
  {
    "tag": "CN8018137D822B",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Elton",
    "checkoutDate": "09/26/2019",
    "dueDate": "09/26/2019",
    "checkinDate": "09/26/2019",
    "notes": null
  },
  {
    "tag": "CN8018171D8447",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Mark",
    "checkoutDate": "09/26/2019",
    "dueDate": "09/26/2019",
    "checkinDate": "09/26/2019",
    "notes": null
  },
  {
    "tag": "CN8018138D8329",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Larry",
    "checkoutDate": "09/26/2019",
    "dueDate": "09/26/2019",
    "checkinDate": "09/26/2019",
    "notes": null
  },
  {
    "tag": "CN8018140D82A6",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Max",
    "checkoutDate": "09/26/2019",
    "dueDate": "09/26/2019",
    "checkinDate": "09/26/2019",
    "notes": null
  },

  {
    "tag": "CN8018136D835F",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "William ",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": null
  },
  {
    "tag": "CN8018137D8217",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Lisa",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8224",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Taylor",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8253",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Maddison",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8236",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Emmy",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8305",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Ashley",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": null
  },
  {
    "tag": "CN8018138D82B5",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Donald",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D81A1",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Sam",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": null
  },
  {
    "tag": "CN8018140D8260",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Wilson",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": null
  },
  {
    "tag": "CN8018136D8179",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Harry",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": null
  },
  {
    "tag": "CN8018142D8300",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Carl",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": null
  },
  {
    "tag": "CN8018137D822B",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Elton",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": null
  },
  {
    "tag": "CN8018171D8447",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Mark",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": null
  },
  {
    "tag": "CN8018138D8329",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Larry",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": null
  },
  {
    "tag": "CN8018140D82A6",
    "type": "Mobile Computer",
    "status": "CHECK OUT",
    "user": "Max",
    "checkoutDate": "09/23/2019",
    "dueDate": "09/23/2019",
    "checkinDate": "09/25/2019",
    "notes": null
  },
    {
    "tag": "CN318140D82BB",
    "type": "Mobile Computer",
    "status": "RETIRED",
    "user": "Max",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": "End of life of device"
  },
  {
    "tag": "CN318140D72AB",
    "type": "Mobile Computer",
    "status": "DAMAGED",
    "user": "Max",
    "checkoutDate": "09/25/2019",
    "dueDate": "09/25/2019",
    "checkinDate": "09/25/2019",
    "notes": "Screen broken"
  },
  {
    "tag": "CN318140D72ZZ",
    "type": "Mobile Computer",
    "status": "CHECK IN",
    "user": "Max",
    "checkoutDate": "08/25/2019",
    "dueDate": "08/25/2019",
    "checkinDate": "09/25/2019",
    "notes": "Scanner not working"
  }

]

export const MobileComponent = ({ children, isMobile }) => isMobile ? children : null

export const isMobile = () => window.screen.width < 500

export const isMobilexs = window.screen.width < 321

export const orgAdmin = '5e694b4f8c9ee9c39fc9489b'

export const deviceAdmin = '5df40d4a0e7301d99b455a62'

export const adminRoles = [deviceAdmin, orgAdmin]

export const MODAL_TOAST_CONTAINER = 'MODAL_TOAST_CONTAINER'

export const devicesFilter = [ 'all', 'mobilecomputer', 'scanner', 'printer', 'other']