import React from 'react'
import PropTypes from 'prop-types'
import {
  Icon
} from '@scuf/common'

import {
  ActionContainer as Container,
  ButtonLabel
} from './controls/controls.styles'

const ActionContainer = ({
  label,
  disabled,
  onClick,
  datatestid,
  ...rest
}) => {
  const handleClick = e => {
    !disabled && (typeof onClick === 'function') && onClick(e)
  }
  return (
    <Container
      disabled={disabled}
      onClick={handleClick}
      data-testid={datatestid}
    >
      <Icon
        {...rest}
      />
      <ButtonLabel>{label}</ButtonLabel>
    </Container>
  )
}

ActionContainer.defaultProps = {
  t: label => label
}

ActionContainer.propTypes = {
  t: PropTypes.func,
  label: PropTypes.string,
  datatestid: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

export default ActionContainer
