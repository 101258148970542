import * as React from 'react'
import { useTranslate } from 'react-translate'
import { Tab, Modal } from '@scuf/common'
import { useAppDispatch } from 'Store'
import ModalActions from 'Redux/ModalRedux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'

import { UserForm } from 'Forms'

import styled from 'styled-components/macro'
import { AddEmployeesForm } from '../add-employees-form'
import { AddUsersForm } from '../add-users-form'
import UserManagerActions from '../../../../Redux/UserManagerRedux'

import { isParentOrgAdmin } from 'Selectors/LoaderSelector'

const ModalContent = styled(Modal.Content)`
  flex: 1;
  & > #tab-container {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
`

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const AddUsersModal: React.FC = () => {
  const translate = useTranslate('AddUsersModal')
  const dispatch = useAppDispatch()
  const flags = useFlags()
  const isEditUserByMailEnabled = flags.bulkEditUsersByMail
  const isCurrentUserOrgAdmin = useSelector(isParentOrgAdmin)

  const closeModal = React.useCallback(() => {
    dispatch(ModalActions.modalClose())
  }, [dispatch])

  const onSuccess = React.useCallback(() => {
    dispatch(UserManagerActions.userManagerRequest(true))
    closeModal()
  }, [dispatch, closeModal])

  return (
    <Container>
      <Modal.Header>{translate('AddUsers')}</Modal.Header>
      <ModalContent scrolling={false}>
        {isEditUserByMailEnabled
          ? (<Tab id='tab-container' defaultActiveIndex={0}>
              <Tab.Pane title={translate('ByEmail')} id='byEmailPane'>
                <UserForm isSSOForm={true} isById={false} />
              </Tab.Pane>
              <Tab.Pane title={translate('ByEmailBulk')} disabled={!isCurrentUserOrgAdmin} id='byEmailBulkPane' >
                <AddUsersForm onCancel={closeModal} onSuccess={onSuccess} />
              </Tab.Pane>
              <Tab.Pane title={translate('ById')} id='byIdPane'>
              <UserForm isSSOForm={true} isById={true} />
              </Tab.Pane>
              <Tab.Pane title={translate('ByIdBulk')} id='byIdBulkPane'>
                <AddEmployeesForm onCancel={closeModal} onSuccess={onSuccess} />
              </Tab.Pane>
            </Tab>)
          : (<Tab id='tab-container' defaultActiveIndex={0}>
              <Tab.Pane title={translate('ByEmail')} id='byEmailPane'>
                <UserForm isSSOForm={true} isById={false} />
              </Tab.Pane>
              <Tab.Pane title={translate('ById')} id='byIdPane'>
              <UserForm isSSOForm={true} isById={true} />
              </Tab.Pane>
              <Tab.Pane title={translate('ByIdBulk')} id='byIdBulkPane'>
                <AddEmployeesForm onCancel={closeModal} onSuccess={onSuccess} />
              </Tab.Pane>
            </Tab>)
        }
      </ModalContent>
    </Container>
  )
}
