import { VerticalMenu, BadgedIcon, Icon, Accordion, Badge } from '@scuf/common';
import React, { useState } from 'react';
import { useTranslate } from 'react-translate';
import PropTypes from 'prop-types';

import { HelpMenuWrapper, StyledPopup, StyledAccordion, VerticalMenuText, ImagePrivacy, PrivacyInfo } from './help-menu.styles';
import { useHelpMenu } from './help-menu.hook';
import ReleaseNotesModal from './release-notes-modal';
import { useSelector } from 'react-redux';
import privacyLogo from '../../Assets/privacyoptions.png'

const HelpLink = 'https://www.honeywellaidc.com/solutions/workflow/operational-intelligence';
const LegalLink = 'https://www.honeywell.com/en-us/terms-and-conditions';
const AboutLink = 'https://www.honeywellaidc.com/solutions/workflow/operational-intelligence';
const PatentsLink = 'https://www.honeywellaidc.com/working-with-us/patents';
const ConditionsLink = 'https://www.honeywell.com/en-us/terms-and-conditions';
const PrivacyLink = 'https://www.honeywell.com/en-us/privacy-statement';
const CookiesLink = 'https://www.honeywell.com/us/en/cookie-notice';
const DoNotSellMyPersonalInformation = 'https://honeywellhub.secure.force.com/PrivacyInformationRequestForm?lang=';

const HelpIcon = ({ areNotificationsPending }) => {
  return areNotificationsPending ? (
    <BadgedIcon className="Icon" id='helpButton' name="badge-help" root="building" size="medium" color="red" empty />
  ) : (
    <Icon className="Icon" name="badge-help" root="building" size="medium" />
  );
};

export const HelpMenu = ({ openModal, theme }) => {
  const [active, setactive] = useState(false);
  const { openOssTerms, openReleaseModal, hasUnreadeReleaseNotes } = useHelpMenu();
  const t = useTranslate('HelpMenu');
  const tRelNotes = useTranslate('ReleaseNotes');
  const lang = useSelector((state) => (state.user.language ? state.user.language : 'en-US'));
  return (
    <HelpMenuWrapper>
      <StyledPopup
        element={<HelpIcon areNotificationsPending={hasUnreadeReleaseNotes} />}
        offset="-150,0"
        on="hover"
        position="bottom left"
      >
        <VerticalMenu width={500}>
          <VerticalMenu.Item id='helpOption' onClick={() => window.open(HelpLink, '_blank', "noopener")}>{t('Help')}</VerticalMenu.Item>
          <VerticalMenu>
            <StyledAccordion>
              <Accordion.Content
                onClick={() => {
                  setactive(!active);
                }}
                active={active}
                id='legalOption'
                title={t('Legal')}
              >
                <VerticalMenu.Header></VerticalMenu.Header>
                <VerticalMenu.Item id='patentsOption' onClick={() => window.open(PatentsLink, '_blank', "noopener")}>{t('Patents')}</VerticalMenu.Item>
                <VerticalMenu.Item id='termsandconditionsOption' onClick={() => window.open(ConditionsLink, '_blank', "noopener")}>
                  {t('TermsAndConditions')}
                </VerticalMenu.Item>
                <VerticalMenu.Item id='pivacyPolicyOption' onClick={() => window.open(PrivacyLink, '_blank', "noopener")}>{t('Privacy')}</VerticalMenu.Item>
                <VerticalMenu.Item id='doNotSellInfoOption' onClick={() => window.open(DoNotSellMyPersonalInformation + lang, '_blank', "noopener")}>
                  <PrivacyInfo>
                    {t('DoNotSellInfo')}
                    <ImagePrivacy src={privacyLogo}/>
                  </PrivacyInfo>
                </VerticalMenu.Item>
                <VerticalMenu.Item id='ossOption' onClick={openOssTerms}>{t('OSS')}</VerticalMenu.Item>
                <VerticalMenu.Item id='cookiesNoticeOption' onClick={() => window.open(CookiesLink, '_blank', "noopener")}>{t('Cookies')}</VerticalMenu.Item>
              </Accordion.Content>
            </StyledAccordion>
          </VerticalMenu>
          <VerticalMenu.Item id='releaseNotesOption' onClick={openReleaseModal}>
            {hasUnreadeReleaseNotes && <Badge color="red" empty />}
            &nbsp;{tRelNotes('Release notes')}
          </VerticalMenu.Item>
          <VerticalMenu.Item id='aboutOption' onClick={() => window.open(AboutLink, '_blank', "noopener")}>{t('About')}</VerticalMenu.Item>
        </VerticalMenu>
      </StyledPopup>
      <ReleaseNotesModal />
    </HelpMenuWrapper>
  );
};

HelpMenu.propTypes = {
  openModal: PropTypes.func,
};

HelpMenu.defaultProps = {
  openModal: () => null,
};

export default HelpMenu;
