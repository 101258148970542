import { createBrowserHistory } from 'history'
import React, { Fragment, Suspense } from 'react'
import { Route, Router, Switch } from 'react-router-dom'

import ExpirationModalHandler from 'Components/ExpirationModal/ExpirationModalHandler'
import AppFrame from 'Containers/AppFrame'
import EndUserLicense from 'features/end-user-license';
import SubscriptionAgreement from 'features/subscription-agreement';

import Callback from '../Containers/Callback'
import EpReportPage from '../Containers/EpReportPage'
import { ROOT_ROUTE_PATTERN as SiteManagementPath } from '../features/site-management/routes'
import asyncComponent from './AsyncComponent'
import Preferences from './Preferences'
import PrivateRoute from './PrivateRoute'
import QrSection from './QrSection'
import { defaultFlags, flagsPropTypes } from 'Utils/launch-darkly-flags'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { pipe } from 'ramda'

import AddLicensesBanner from 'features/v2/AddLicensesBanner'

const AsyncGatewayDevicePage = asyncComponent(() => import('../Containers/GatewayDevicePage'))
const AsyncDashboardBatteries = asyncComponent(() => import('../Containers/DashboardBatteries'))
const AsyncAssetsGatewaysPage = asyncComponent(() => import('../Containers/AssetsGatewaysPage'))
const AsyncAssetsFirmwarePage = asyncComponent(() => import('../Containers/AssetsFirmwarePage'))
const AsyncDeviceDetailsPage = asyncComponent(() => import('../Containers/DeviceDetailsPage'))
const AsyncDashboardManagementView = asyncComponent(() => import('../Containers/DashboardManagementView'))
const AsyncDevicesOutOfRange = asyncComponent(() => import('../Containers/DevicesOutOfRange'))
const AsyncRMAOverview = asyncComponent(() => import('features/v2/DeviceAdministration/RMAOverview/index'))
const AsyncRMAOverviewDetail = asyncComponent(() => import('features/v2/DeviceAdministration/RMAOverview/RMAOverviewDetail/index'))
const AsyncUserManagementPage = asyncComponent(() => import('../features/user-management'))
const AsyncDeviceAdministrationPage = asyncComponent(() => import('../features/logging-diagnostics'))
const AsyncOtherAssetManagementView = asyncComponent(() => import('../features/non-iot-management'))
const AsyncUserProfilePage = asyncComponent(() => import('../features/user-profile'))
const AsyncRoleDetailsPage = asyncComponent(() => import('../features/user-management/components/Role/role-details'))
const AsyncSiteManagementView = asyncComponent(() => import('../Containers/SiteManagementView'))
const AsyncBatteryDetailsPage = asyncComponent(() => import('../Containers/BatteryDetailsPage'))
const AsyncOperationsSupportPage = asyncComponent(() => import('../features/operations-support'))
const AsyncOrganizationAnalyticsPage = asyncComponent(() => import('../features/operations-support/components/org-analytics'))
const AsyncRulesEnginePage = asyncComponent(() => import('../features/rules-engine'))
const AsyncRulesStepperPage = asyncComponent(() => import('../features/rules-engine/components'))
const AsyncViewAutomationRule = React.lazy(() => import('features/rules-engine/components/view-rule'))
const AsyncEditAutomationRule = React.lazy(() => import('features/rules-engine/components/edit-rule'))
const AsyncEditRule = React.lazy(() => import('features/rules-engine/create-rule/edit-index'))
const AsyncEditAction = React.lazy(() => import('features/rules-engine/create-rule/edit-index-action'))
const AsyncCreateRule = React.lazy(() => import('features/rules-engine/create-rule'))
// const AsyncViewRule = React.lazy(() => import('../features/rules-engine/view-rule'))
const AsyncRulesEngineClientPage = React.lazy(() => import('../features/rules-engine/tables/client-table'))
const AsyncOrgInternalReporting = asyncComponent(() => import('features/org-internal-reporting/org-internal-reporting-dashboard'))
const AsyncSubscriptionPage = React.lazy(() => import('./Subscription'))
const AsyncSubscriptionManagementPage = React.lazy(() => import('features/SubscriptionManagement'))

const AsyncDeviceAlerts = asyncComponent(() => import('./Alerts/components/DeviceAlert'))
const AsyncDashboard = asyncComponent(() => import('../Containers/Dashboard'))
const AsyncSiteComparison = asyncComponent(() => import('../Containers/SiteComparison'))
const AsyncSiteRanking = asyncComponent(() => import('../Containers/SiteRanking'))
const AsyncDashboardExecutive = asyncComponent(() => import('../Containers/DashboardExecutive'))
const ExecDashboard = asyncComponent(() => import('./ExecDashboard/ExecDashboard'))
const AsyncAssetReports = asyncComponent(() => import('../Containers/AssetsReports'))
const AsyncAssetsDetailsART = asyncComponent(() => import('../Containers/AssetsDetails'))
const AsyncMarketplace = asyncComponent(() => import('../Containers/MarketplaceManagement'))
const AsyncUserActivityReport = asyncComponent(() => import('../Components/UserActivityReport'))
const AsyncApplicationReport = asyncComponent(() => import('../features/ApplicationReport'))

const AsyncBuildingView = asyncComponent(() => import('./Building'))
const AsyncAlertsPage = asyncComponent(() => import('./Alerts'))
const CounterPage = asyncComponent(() => import('Containers/Counter'))
const AsyncConfigurationManagement = asyncComponent(() => import('./ConfigurationManagement'))
const AsyncAssetManagement = asyncComponent(() => import('./AssetManagementBase'))
const SiteManagement = React.lazy(() => import('../features/site-management'))
const ContactTracing = React.lazy(() => import('../features/contact-tracing-report'))
const ReportEvents = React.lazy(() => import('../features/event-report'))
const ReportDownloads = React.lazy(() => import('../features/report-downloads'))
const AsyncScheduledTaskView = React.lazy(() => import('./software-scheduled-task-view'))
const AsyncOauthCallback = React.lazy(() => import('features/sso-oauth2'))
const AsyncLoginDirect = React.lazy(() => import('features/v2/login-direct'))
const AccessPoints = asyncComponent(() => import('Containers/AccessPoints'))
const AsyncLogin = React.lazy(() => import('Components/LoginSection'))
const AsyncLogout = React.lazy(() => import('features/sso-oauth2/components/logout'))
const AsyncSubscriptionExtend = React.lazy(() => import('features/SubscriptionManagement/Features/SubscriptionActions'))
const AsyncBatteryDischargeRateDetails = React.lazy(() => import('features/v2/Dashboard/BatteryHealth/BatteryDischargeRate/Details/BatteryDischargeRateDetails/BatteryDischargeRateDetails'))
const AsyncExpectedRuntimeDetails = React.lazy(() => import('features/v2/Dashboard/BatteryHealth/BatteryDischargeRate/Details/ExpectedRuntimeDetails/ExpectedRuntimeDetails'))
const AsyncDataSolution = asyncComponent(() => import('../features/data-solution'))
const AsyncAnomalyOverview = asyncComponent(() => import('features/v2/AnomalyOverview/index'))
const AsyncAnomalyOverviewDetail = asyncComponent(() => import('features/v2/AnomalyOverview/AnomalyOverviewDetail/index'))

export const history = createBrowserHistory();


// const reportOptions = reportAutorization
//   ? <Fragment>
//     <PrivateRoute exact={true} path='/tools' component={AsyncTooolsPage} />
//     <PrivateRoute exact={true} path='/reports/tco' component={AsyncReportTcoListPage} />
//     <PrivateRoute exact={true} path='/reports/haas' component={AsyncReportHaasListPage} />
//     <PrivateRoute exact={true} path='/reports/tco/create' component={ReportTcoPrePage} />
//     <PrivateRoute exact={true} path='/reports/haas/create' component={ReportHaasPrePage} />
//     <PrivateRoute exact={true} path='/reports/tco/generate' component={AsyncReportTcoPage} />
//     <PrivateRoute exact={false} path='/reports/tco/generate/:id' component={AsyncReportTcoPage} />
//     <PrivateRoute exact={true} path='/reports/haas/generate' component={AsyncReportHaasPage} />
//     <PrivateRoute exact={true} path='/reports/tco/configuration' component={ReportTcoConfig} />
//     <PrivateRoute exact={true} path='/reports/haas/configuration' component={ReportHaasConfig} />
//   </Fragment>
//   : null

const Navigation = ({
  flags
}) => (
  <Router history={history}>
    <Suspense fallback={<Fragment />}>
      <Switch>
        <Route exact={true} path='/callback' component={Callback} />
        <Route exact={true} path='/login' component={AsyncLogin} />
        <Route exact={true} path='/oauth-callback' component={AsyncOauthCallback} />
        <Route exact={true} path='/logout' component={AsyncLogout} />
        <Route exact={false} path='/login-direct' component={AsyncLoginDirect} />
        <Route exact={true} path='/' component={Callback} />
        <Route path='/'>
          <EndUserLicense>
            <SubscriptionAgreement >
              <AddLicensesBanner />
              <AppFrame>
                <Switch>
                  <PrivateRoute exact={true} path='/dashboard/devices' component={AsyncGatewayDevicePage} />
                  <PrivateRoute exact={true} path='/dashboard/batteries' component={AsyncDashboardBatteries} />
                  <PrivateRoute exact={true} path='/assets/devices' component={AsyncAssetsGatewaysPage} />
                  <PrivateRoute exact={true} path='/assets/firmware' component={AsyncAssetsFirmwarePage} />
                  <PrivateRoute exact={true} path='/assets/updates' component={AsyncConfigurationManagement}  permission='device:update:view' />
                  <PrivateRoute exact={true} path='/assets/updates/:task_id' component={AsyncScheduledTaskView} />
                  <PrivateRoute exact={false} path='/assets/devices/:id' component={AsyncDeviceDetailsPage} />
                  <PrivateRoute exact={false} path='/assets/gateways/:id' component={AsyncDeviceDetailsPage} />
                  <PrivateRoute exact={false} path='/assets/batteries/:id' component={AsyncBatteryDetailsPage} />
                  <PrivateRoute exact={true} path='/assets/locations' component={AsyncBuildingView} />
                  <PrivateRoute exact={true} path='/admin/user_management' component={AsyncUserManagementPage}  permission='user:read' />
                  <PrivateRoute exact={true} path='/device_admin/diagnosticfiles' component={AsyncDeviceAdministrationPage}   permission='device:logging' />
                  <PrivateRoute exact={true} path='/admin/user_profile' component={AsyncUserProfilePage} />
                  <PrivateRoute exact={true} path='/admin/role_management' component={AsyncUserManagementPage}  permission='user:read' />
                  <PrivateRoute exact={true} path='/admin/asset_configuration_management' component=  {AsyncOtherAssetManagementView} />
                  <PrivateRoute exact={false} path='/admin/operations_support/:orgName/:id' component={AsyncOrgInternalReporting}   permission='operations_support:read' />
                  <PrivateRoute exact={true} path='/admin/dashboard_management' component={AsyncDashboardManagementView} />
                  <PrivateRoute exact={false} path='/admin/role_management/:id' component={AsyncRoleDetailsPage} />
                  <PrivateRoute exact={true} path='/admin/devices_out_of_range' component={AsyncDevicesOutOfRange} />
                  <PrivateRoute exact={true} path='/device_admin/RMAOverview' component={AsyncRMAOverview} />
                  <PrivateRoute exact={true} path='/device_admin/RMAOverview-detail' component={AsyncRMAOverviewDetail} />
                  <PrivateRoute exact={true} path='/admin/marketplace' component={AsyncMarketplace} />
                  <PrivateRoute exact={true} path='/admin/legacy_site_management' component={AsyncSiteManagementView} />
                  <PrivateRoute exact={true} path='/admin/user_activity_log' component={AsyncUserActivityReport} />
                  <PrivateRoute exact={true} path='/reports/application_reports' component={AsyncApplicationReport} />
                  <PrivateRoute exact={true} path='/admin/operations_support' component={AsyncOperationsSupportPage}  permission='operations_support:read' />
                  <PrivateRoute exact={false} path={SiteManagementPath} component={SiteManagement} permission='site:read' />
                  <PrivateRoute exact={true} path='/admin/device_registration' component={QrSection} />
                  <PrivateRoute exact={true} path='/admin/enterprise_provisoner' component={EpReportPage} />
                  <PrivateRoute exact={false} path='/counter/:siteId' component={CounterPage} permission='counter:app_usage' />
                  <PrivateRoute exact={true} path='/alerts' component={AsyncAlertsPage} permission='alert:read' />
                  <PrivateRoute exact={true} path='/alerts/:siteId/:deviceId' component={AsyncDeviceAlerts} />
                  <PrivateRoute exact={true} path='/dashboard/sites' component={AsyncDashboard} permission='dashboard:read' />
                  <PrivateRoute exact={true} path='/siteComparison' component={AsyncSiteComparison} />
                  <PrivateRoute exact={true} path='/siteRanking' component={AsyncSiteRanking} />
                  <PrivateRoute exact={true} path='/execDashboard' component={ExecDashboard} />
                  <PrivateRoute exact={true} path='/assets/management/:type' component={AsyncAssetManagement}   permission='asset:read' />
                  <PrivateRoute exact={true} path='/assets/reports' component={AsyncAssetReports} />
                  <PrivateRoute exact={true} path='/assets/reports/:type' component={AsyncAssetReports} />
                  <PrivateRoute exact={false} path='/assets/management/devices/:id' component={AsyncAssetsDetailsART} />
                  <PrivateRoute exact={false} path='/assets/management/gateways/:id' component={AsyncAssetsDetailsART} />
                  <PrivateRoute exact={false} path='/preferences' component={Preferences} />
                  <Route exact={true} path='/dashboard/executive/:siteId/:section?' component={AsyncDashboardExecutive} />
                  <PrivateRoute exact={true} path='/reports/contact_tracing' component={ContactTracing} />
                  <PrivateRoute exact={true} path='/reports/events' component={ReportEvents} />
                  <PrivateRoute exact={true} path="/reports/downloads" component={ReportDownloads} />
                  <PrivateRoute exact={true} path='/admin/operations_support/analytics' component={AsyncOrganizationAnalyticsPage}  permission='operations_support:read' />
                  <PrivateRoute exact={true} path='/device_admin/rules' component={AsyncRulesEnginePage} permission='rules:read' />
                  <PrivateRoute exact={true} path='/device_admin/rules/create' component={AsyncRulesStepperPage}  permission='rules:create' />
                  <PrivateRoute exact={true} path='/device_admin/rules/view/:ruleId' component={AsyncViewAutomationRule}  permission='rules:read' />
                  <PrivateRoute exact={true} path='/device_admin/rules/edit/:ruleId' component={AsyncEditAutomationRule}  permission='rules:create' />
                  <PrivateRoute exact={false} path='/access_points' component={AccessPoints} />
                  <PrivateRoute exact={true} path='/subscriptionExtend' component={AsyncSubscriptionExtend} />
                  <PrivateRoute exact={true} path='/subscriptionUpgrade' component={AsyncSubscriptionExtend} />
                  <PrivateRoute exact={true} path='/subscriptionAddDevices' component={AsyncSubscriptionExtend} />
                  <PrivateRoute exact={true} path='/dashboard/sites/batteryDischargeRateDetails' component={AsyncBatteryDischargeRateDetails} />
                  <PrivateRoute exact={true} path='/dashboard/sites/batteryExpectedRuntimeDetails' component={AsyncExpectedRuntimeDetails} />
                  <PrivateRoute exact={true} path='/anomalyOverview' component={AsyncAnomalyOverview} />
                  <PrivateRoute exact={true} path='/anomalyOverview/detail' component={AsyncAnomalyOverviewDetail} />
                  { flags.dataSolution && <PrivateRoute exact={false} path='/data-solution' component={AsyncDataSolution} /> }
                  <PrivateRoute
                    path='/automation/create-rule'
                    component={AsyncCreateRule}
                    permission='rules:create'
                  />
                  <PrivateRoute
                    path='/automation/:ruleId/edit'
                    component={AsyncEditRule}
                    permission='rules:create'
                  />
                  <PrivateRoute
                    path = '/automation/:ruleId/edit/action'
                    component={AsyncEditAction}
                    permission='rules:create'
                    />
                  {/* <PrivateRoute exact={true} path='/automation/:ruleId' component={AsyncViewRule} permission='rules:read' /> */}
                  <PrivateRoute
                    exact={true}
                    path='/automation'
                    component={AsyncRulesEngineClientPage}
                    permission='rules:read'
                  />
                  { flags.subscriptionView &&
                    <PrivateRoute
                      exact={true}
                      path='/subscription'
                      component={AsyncSubscriptionPage}
                    />
                  }
                  { flags.subscriptionManagement &&
                    <PrivateRoute
                      exact={true}
                      path='/subscriptionManagement'
                      component={AsyncSubscriptionManagementPage}
                    />
                  }
                </Switch>
                <ExpirationModalHandler />
              </AppFrame>
            </SubscriptionAgreement>
          </EndUserLicense>
        </Route>
      </Switch>
    </Suspense>
  </Router>
);

Navigation.propTypes = {
  flags: flagsPropTypes
}

Navigation.defaultProps = {
  flags: defaultFlags,
}

export default pipe(
  withLDConsumer()
)(Navigation)
