import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from '@scuf/common'
import { defaultFlags, flagsPropTypes } from 'Utils/launch-darkly-flags'
import { SimpleDiv } from './assets-details.styles'
import { Tooltip } from '@scuf/common'
import { useTranslate } from 'react-translate'

const CollapseContainer = styled.div`
font-family: "Honeywell Sans Web";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 22px;
`

export const UnlicensedTooltip = ({
  content = 'renew',
  position,
  disabled,
  children
}) => {
  const t = useTranslate('UnlicensedTooltip')
  return (
    <Tooltip
      disabled={disabled}
      content={t(content)}
      element={<div>{children}</div>}
      event='hover'
      hoverable={true}
      position={position}
    />
  )
}

const Collapse = ({
  category,
  children,
}) => {
  const [ select, setSelect ] = useState(false)
  return (
    <CollapseContainer>
      <div onClick={() => setSelect(!select)}>
        {category}
        <Icon name='caret-down' size='medium' />
      </div>
        {select && children}
    </CollapseContainer>
  )
}

export const MaintenanceButton = ({
  t,
  onClick,
  flags,
  deviceDbStatus
}) => {
  const isLicensingEnabled = (flags.licensing && deviceDbStatus === 'unlicensed')
  return (
    <CollapseContainer>
      <UnlicensedTooltip
        position='top center'
        content= 'renew'
        disabled={deviceDbStatus === 'active'}
        >
          <SimpleDiv className={ isLicensingEnabled  ? 'disableOptions' : 'pointer'}>
            <div style={{display: 'flex'}} onClick={onClick}>
              <Icon name='tools'  root='building' size='medium' data-testid="maintenanceIcon"/>
              <div style={{paddingLeft: '8px'}} data-testid="maintenanceLabel">{t('Maintenance')}</div>
            </div>
          </SimpleDiv>
        </UnlicensedTooltip>
    </CollapseContainer>
  )
}

Collapse.defaultProps = {
  flags: defaultFlags,
}
MaintenanceButton.defaultProps = {
  flags: defaultFlags,
}
MaintenanceButton.propTypes = {
  flags:flagsPropTypes,
}
UnlicensedTooltip.defaultProps = {
  flags: defaultFlags,
}
UnlicensedTooltip.propTypes = {
  flags:flagsPropTypes,
}
Collapse.propTypes = {
  category: PropTypes.string,
  children: PropTypes.node,
  flags:flagsPropTypes,
}
export default Collapse
