import Immutable from 'seamless-immutable'
import { createSelector, createStructuredSelector } from 'reselect'
import { sortBy, get } from 'lodash'
import { OP_INTEL_KEY } from 'Redux/LoginRedux'
import { organizationUsersReducer } from 'features/user-management/users-list/organization-users-reducer'

export const organizationsIds = ({ user }) => user.organizations
export const headerToken = ({ login }) => login.headerToken
export const firstTimeLogin = ({ login }) => login.firstTimeLogin
export const accessToken = ({ login }) => login.accessToken

export function loggedIn (state) {
  return state.login.getIn(['loggedIn'])
}

export function idToken (state) {
  return state.login.getIn(['idToken'])
}

export function token (state) {
  return state.login.getIn(['accessToken'])
}

export function isLoadingDevices (state) {
  return state.devices.getIn(['loading'])
}

export function isLoadingInBackground (state) {
  return state.devices.getIn(['loadingBackground'], false)
}

export function isLoadingSites (state) {
  return state.siteManager.getIn(['loading'])
}

export function isLoadingUsers (state) {
  return state.userManager.getIn(['loading'])
}

export function isLoadingDashboards (state) {
  return state.dashboard.getIn(['loading'])
}

const isLoadingUsersList = (state) => state[organizationUsersReducer.slice].loading

export function isAdmin (state) {
  return state.user.getIn(['isAdmin'])
}

export function isDeviceAdmin (state) {
  return state.user.getIn(['roles']).filter(value => value === 'Device Administrator').length > 0
}

export function isHoneywellAdmin (state) {
  return state.user.getIn(['isHoneywellAdmin'])
}

export function userRole (state) {
  return state.user.getIn(['userRole'])
}

export function getGenericRoles (state) {
  return Object.values(state.roles.roles.getIn(['generic'], []))
}

export function getOrgs (state) {
  return Object.values(state.siteManager.getIn(['rootSites'], []))
}

export function getOrgIds (state) {
  const orgs = state.siteManager.getIn(['rootSitesById'])
  return orgs.map(org => org.text.trim())
}

export function getOrgUsers (state) {
  return Object.values(state.userManager.getIn(['users']))
}

export const currentUser = state => state.userManager.currentUser

export const userName = state => state.login.userName

const userFirstName = state => state.user.firstName

const subscriptionName = state => state.user?.subscription?.name

export const modal = state => state.login.getIn(['modal'], true)

export const loginError = state => state.login.getIn(['error'], false)

export const isLoadingUpdates = ({ updates }) => updates.loading

export const theme = ({ user }) => user.getIn(['theme'], {})

export const userId = ({ user }) => user.getIn(['userData', 'id'], '')

export const userOrgsAssociation = ({ user }) => user.getIn(['userData', 'assetAssociation'], [])

export const hiddenContent = ({ userSettings }) => userSettings.getIn(['hiddenContent'], false)

export const organizations = createSelector(
  organizationsIds,
  (organizations) => sortBy(organizations.map(org => ({
    text: org.siteName,
    value: org.siteId
  })), 'text')
)

export const localStorageOrgId = ({ user }) => user.getIn(['selectedOrganizationId'], '')

export const firstOrg = createSelector(
  organizations,
  orgs => get(orgs, '0.value', '')
)

export const selectedOrgId = createSelector(
  localStorageOrgId,
  firstOrg,
  (selectedOrg, firstOrg) => selectedOrg || firstOrg // 🙄 solutions solutions by: neto
)

export const isUserOrgAdmin = createSelector(
  localStorageOrgId,
  userOrgsAssociation,
  (selectedOrgId, userOrgs) => {
    const AdminRoles = ['Administrator']
    const selectedUserOrg = userOrgs
      .filter((userOrg) => userOrg.organizationIdentifier['organizationGuid'] === selectedOrgId)
    return selectedUserOrg[0]?.roles.some((role) => AdminRoles.includes(role.name))
  }
)

export const isUserOrgGlobalAdmin = createSelector(
  localStorageOrgId,
  userOrgsAssociation,
  (selectedOrgId, userOrgs) => {
    const OrgAdminRoles = ['Organization Admin']
    const selectedUserOrg = userOrgs
      .filter((userOrg) => userOrg.organizationIdentifier['organizationGuid'] === selectedOrgId)
    return selectedUserOrg[0]?.roles.some((role) => OrgAdminRoles.includes(role.name))
  }
)

export const isParentOrgAdmin = createSelector(
  isUserOrgAdmin,
  isUserOrgGlobalAdmin,
  (isOrgAdmin, isOrgGlobalAdmin) => {
    return isOrgAdmin || isOrgGlobalAdmin
  }
)

export const selectedUserOrgRoles = createSelector(
  isUserOrgGlobalAdmin,
  getGenericRoles,
  (isOrgGlobalAdmin, roles) => {
    if(!isOrgGlobalAdmin)
      return roles.filter(role => role.name != 'Organization Admin' && role.name != 'Device Administrator')
    else
      return roles
  }
)

export const selectedValidRoles = createSelector(
  selectedUserOrgRoles,
  (userOrgRoles) => {
    return userOrgRoles.map(role => role.name)
  }
)

export const loadingSelector = createSelector(
  [
    isLoadingDevices,
    isLoadingSites,
    isLoadingUsers,
    isLoadingDashboards,
    isLoadingInBackground,
    isLoadingUpdates,
    isLoadingUsersList
  ],
  (...selectors) => {
    return selectors.reduce((loading, selector) => selector || loading, false)
  }
)

export const generalLoadingSelector = createStructuredSelector({
  ssoOauth: ({ ssoOauth }) => ssoOauth,
  isHoneywellAdmin,
  isAdmin,
  isDeviceAdmin,
  loggedIn,
  idToken,
  token,
  userName,
  userRole,
  loading: loadingSelector,
  error: loginError,
  modal,
  userFirstName,
  theme,
  userId,
  organizations,
  headerToken,
  selectedOrgId,
  localStorageOrgId,
  firstTimeLogin,
  accessToken,
  hiddenContent,
  firstOrg,
  subscriptionName
})
