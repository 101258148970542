const create = (api) => {
  const getAnomalyDetailsData = (siteId, startDate, endDate, anomalyId, language, pageNumber, pageSize) => {
    return api.get(`/anomalies/${siteId}/detail?startDate=${startDate}&endDate=${endDate}&anomalyId=${anomalyId}&language=${language}&pageNumber=${pageNumber}&pageSize=${pageSize}&includeDetails=true`)
  }

  const getAnomalyInfoData = (siteId, startDate, endDate, anomalyId, language, pageNumber=1, pageSize=30) => {
    return api.get(`/anomalies/${siteId}/detail?startDate=${startDate}&endDate=${endDate}&anomalyId=${anomalyId}&language=${language}&pageNumber=${pageNumber}&pageSize=${pageSize}&includeDetails=false`)
  }

  const getToRetrieveAnomaly = (startDate, endDate, siteId, includeChildren=true, sort='null', directionSort='null', searchTerm, language, pageNumber=1, pageSize=100) => {
    const query = searchTerm === undefined ? `&query=` : `&query=${searchTerm}`
    return api.get(`/anomalies/${siteId}?startDate=${startDate}&endDate=${endDate}&includeChildren=${includeChildren}&language=${language}&pageNumber=${pageNumber}&pageSize=${pageSize}`+ `${query}` +`&sort=${sort}&directionSort=${directionSort}`)
  }

  const postCloseAnomaly = (siteId, anomalyId) => {
    return api.post(`/anomalies/${siteId}?anomaly=${anomalyId}`)
  }

  const postExportAnomaly = (startDate, endDate, siteId, includeChildren, chartName, sort, directionSort, searchTerm, language) => {
    const params = {
      "siteList": [],
      "serialNumberList": []
    }
    const query = searchTerm === undefined ? `&query=` : `&query=${searchTerm}`
    return api.post(`dashboard/exportfiles/${siteId}?startDate=${startDate}&endDate=${endDate}`+ `${query}` +`&includeChildren=${includeChildren}&chartName=${chartName}&language=${language}&sort=${sort}&directionSort=${directionSort}`, { params })
  }

  return {
    getAnomalyDetailsData,
    getAnomalyInfoData,
    getToRetrieveAnomaly,
    postExportAnomaly,
    postCloseAnomaly
  }

}

export default {
  create
}
